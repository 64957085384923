<template>
  <div>
    <md-dialog :md-close-on-esc="false" :md-click-outside-to-close="false"
               :md-active.sync="isModalVisible">

      <md-dialog-content>
        <form novalidate @submit.prevent="onSubmit">

          <span class="md-headline">{{ title }}</span>

          <div class="md-layout md-gutter md-alignment-center">

            <div class="md-layout-item">
              <md-field>
                <label>État</label>
                <md-select v-model="object.status">
                  <md-option :value="orderStatus.created">Créée</md-option>
                  <md-option :value="orderStatus.ready">Prête</md-option>
                  <md-option :value="orderStatus.completed">Terminée</md-option>
                  <md-option :value="orderStatus.cancelled">Annulée</md-option>
                </md-select>
              </md-field>
            </div>

            <div class="md-layout-item">
              <md-field>
                <label class="input-date-label">Date</label>
                <datetime
                  value-zone="Europe/Zurich"
                  :format="dateFormat"
                  type="datetime"
                  input-class="input-datetime"
                  v-model="object.date"
                  auto-continue
                  auto-close
                  placeholder="Sélectionner une date"
                  :phrases="{ok:'ok', cancel:'annuler'}"
                />
              </md-field>
            </div>

            <div class="md-layout-item">
              <md-field>
                <label>Paiement</label>
                <md-select v-model="object.payment">
                  <md-option :value="paymentStatus.pending">En attente</md-option>
                  <md-option :value="paymentStatus.toBeVerified">À vérifier</md-option>
                  <md-option :value="paymentStatus.paid">Payée</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter md-alignment-center">
            <div class="md-layout-item">
              <md-autocomplete :input="object.user" :value="buyer"
                               :md-options="users"
                               @md-changed="getUsers"
                               @md-opened="getUsers" @md-selected="addBuyer">
                <label>Acheteur</label>
                <template slot="md-autocomplete-item" slot-scope="{item}">
                  {{ item.email }}
                </template>
                <template slot="md-autocomplete-empty" slot-scope="{term}">
                  pas de résultat pour:{{ term }}
                </template>
              </md-autocomplete>
            </div>

            <div class="md-layout-item">
              <md-field>
                <label>Rabais</label>
                <md-input type="number" class="small-input" v-model="object.discount"/>
              </md-field>
            </div>
          </div>

          <div>
            <div class="md-layout md-alignment-center">
              <md-icon class="shop-icon">shopping_bag</md-icon>
              <span class="md-list-item-text">Produits</span>
              <div class="align-end">
                <div>Total brut: CHF {{ total | decimal }}</div>
                <div>Total: CHF {{ total - (object.discount || 0) | decimal }}</div>
              </div>
            </div>

            <md-autocomplete v-model="value" :md-options="products" @md-changed="getProducts"
                             @md-opened="getProducts" @md-selected="addProduct">
              <label>Cherchez un produit...</label>
              <template slot="md-autocomplete-item" slot-scope="{item}">
                <div>
                  <md-avatar>
                    <img :src=getFile(item.image_path) alt="photo du produit">
                  </md-avatar>
                  <span class="margin-left">{{ item.name }}</span>
                </div>
                <span>CHF {{ item.price }}</span>
              </template>
              <template slot="md-autocomplete-empty" slot-scope="{term}">
                pas de résultat pour: {{ term }}
              </template>
            </md-autocomplete>

            <md-list slot="md-expand">
              <md-list-item v-for="product in object.products" :key="product.id">
                <md-avatar>
                  <img :src=getFile(product.image_path) alt="photo du produit">
                </md-avatar>

                <div class="md-list-item-text">
                  <span>{{ product.name }}</span>
                  <div class="md-layout md-alignment-center">
                    <span class="md-layout-item">CHF {{ product.price }}</span>
                    <input class="input-quantity" placeholder="quantity" type="number"
                           v-model="product.pivot.quantity"/>
                  </div>
                </div>

                <md-button @click="removeProduct(product)"
                           class="md-icon-button md-list-action md-dense md-raised md-accent">
                  <md-icon>delete_outline</md-icon>
                </md-button>
              </md-list-item>

            </md-list>
          </div>

          <div class="buttons-panel">
            <md-button class="md-raised md-accent" @click="close">Annuler</md-button>
            <md-button type="submit" class="md-raised md-primary">Valider</md-button>
          </div>

        </form>
      </md-dialog-content>
    </md-dialog>
    <div class="fab-space"/>
  </div>
</template>

<script>
import OrderService from '@/components/order/OrderService';
import { mapGetters } from 'vuex';
import { sqlDateTimeFormat } from '@/date-format';
import translationMixin from '@/mixins/translation-mixin';
import UserService from '@/components/user/UserService';
import { Datetime } from 'vue-datetime';
import staticFileMixin from '@/mixins/static-file-mixin';
import ProductService from '@/components/product/ProductService';
import orderStatus from '@/components/order/order-status';
import paymentStatus from '@/components/order/payment-status';
import formMixin from '@/mixins/form-mixin';
import { DateTime } from 'luxon';

export default {
  name: 'OrderForm',
  data: () => ({
    model: 'Commande',
    is_feminine: true,
    service: OrderService,
    orderStatus,
    paymentStatus,
    dateFormat: sqlDateTimeFormat,
    users: [],
    products: [],
    value: '',
  }),
  components: {
    Datetime,
  },
  mixins: [formMixin, translationMixin, staticFileMixin],
  filters: {
    decimal(number) {
      return number.toFixed(2);
    },
  },
  computed: {
    ...mapGetters(['getFirstUserId']),
    total() {
      if (this.object.products) {
        return this.object.products
          .reduce((total, product) => total + (product.price * product.pivot.quantity), 0);
      }
      return 0;
    },
    buyer() {
      return this.object.id ? this.object.user.email : '';
    },
  },
  methods: {
    getDefaultObject() {
      return {
        user_id: this.getFirstUserId,
        date: DateTime.now().toISO(),
        status: orderStatus.completed,
        payment: paymentStatus.paid,
        products: [],
      };
    },
    getUsers(searchTerm) {
      if (typeof searchTerm === 'string' || searchTerm === undefined) {
        this.users = UserService.searchByEmail(searchTerm);
      }
    },
    addBuyer(user) {
      this.object.user = user;
      this.object.user_id = user.id;
    },
    getProducts(searchTerm) {
      if (typeof searchTerm === 'string' || searchTerm === undefined) {
        this.products = ProductService.searchAndFilter(searchTerm, this.object.products);
      }
    },
    addProduct(product) {
      const productWithQuantity = { ...product, pivot: { quantity: 1 } };
      if (!this.object.products) {
        this.object.products = [];
      }
      this.object.products.push(productWithQuantity);
      this.resetValue();
    },
    resetValue() {
      //wait before resetting the field otherwise another process might overwrite it
      setTimeout(() => {
        this.value = '';
      }, 10);
    },
    removeProduct(product) {
      const index = this.object.products.indexOf(product);
      this.object.products.splice(index, 1);
    },
  },
};
</script>

<style>

.shop-icon {
  margin-right: 16px !important;
}

.margin-left {
  margin-left: 16px;
}

.input-quantity {
  width: 70px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.align-end {
  text-align: end;
}

</style>
