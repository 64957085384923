import { DateTime } from 'luxon';
import { frenchDateTimeFormat } from '@/date-format';
import frenchStrings from '@/strings';

export default {
  filters: {
    dateFR(date) {
      if (!date) return '';
      return DateTime.fromISO(date).toFormat(frenchDateTimeFormat);
    },
    toFR: (texte) => frenchStrings[texte],
  },
};
