<template>
  <div>
    <md-list-item class="md-layout md-alignment-center">

      <div class="md-layout md-xsmall-size-90">
        <div class="md-layout-item md-xsmall-size-100 md-list-item-text">
          <span>{{ object.id }}</span>
        </div>
        <div class="md-layout-item md-xsmall-size-100">
          <span>{{ object.date | dateFR }}</span>
        </div>
        <div class="md-layout-item md-xsmall-size-100 md-list-item-text">
          <span>{{ object.user.name }}</span>
        </div>
        <div class="md-layout-item md-xsmall-size-60 md-small-hide">
          <span>{{ object.status | toFR }}</span>
        </div>
        <div class="md-layout-item md-xsmall-size-15 md-small-hide">
          <span>{{ object.payment | toFR }}</span>
        </div>
      </div>

      <div class="md-layout md-layout-item md-size-10 md-small-20 md-xsmall-size-10">
        <div class="btn-edit">
          <md-button @click="onEdit" class="md-icon-button md-dense md-raised md-accent btn-green">
            <md-icon>mode</md-icon>
          </md-button>
        </div>
        <md-button @click="active = true" class="md-icon-button md-dense md-raised md-accent">
          <md-icon>delete</md-icon>
        </md-button>
      </div>

      <md-dialog-confirm
        :md-active.sync="active"
        md-title="Supprimer commande"
        md-content="Vous voulez vraiment supprimer cette commande?"
        md-confirm-text="Confirmer"
        md-cancel-text="Annuler"
        @md-confirm="onConfirm"/>

    </md-list-item>
    <md-divider class="md-full"/>
  </div>
</template>

<script>

import OrderService from '@/components/order/OrderService';
import translationMixin from '@/mixins/translation-mixin';
import itemMixin from '@/mixins/item-mixin';

export default {
  name: 'OrderItem',
  data: () => ({
    service: OrderService,
  }),
  mixins: [itemMixin, translationMixin],
};

</script>
