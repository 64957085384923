import APIService from '@/service/APIService';
import http from '@/http/token-http';

class UserService extends APIService {
  constructor() {
    super('/users');
  }

  searchByEmail(emailTerm = '', participants) {
    const email = emailTerm.toLowerCase();
    return http.get(this.resource, { params: { email, page: 1 } })
      .then((res) => res.data.data)
      .then((users) => {
        if (participants) {
          return users.filter((user) => !participants
            .find((participant) => participant.id === user.id));
        }
        return users;
      });
  }
}

export default new UserService();
